import React from "react"

import { LandingScreen } from "../Home/Styled"
// import { Link } from "gatsby"
import staffing from "../../images/staffing.jpg"
import Automation_Leads_Ageing_Distribution_highres from "../../images/Automation_Leads_Ageing_Distribution_highres.jpg"
import Custom_Function_hires from "../../images/Custom_Function_hires.jpg"
// import RecruitPro_Workflow_hires from "../../images/RecruitPro_Workflow-hires.jpg"

import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { loginUrl } from "../../config/keys"
import contactImg from "../../images/Contact.jpg"
// import {
//   Carousel,
//   CarouselItem,
//   CarouselControl,
//   CarouselIndicators,
// } from "reactstrap"
import "./Industries.css"
import Signup from "../Home/signup"
import ScrollToTop from "./ScrollToTop"
// import contactImg from "../../images/Contact.jpg"
import contact_mobile from "../../images/contact_mobile.jpg"
import RecruitPro_Workflow_hires from "../../images/RecruitPro_Workflow_hires.jpg"

// import clsx from "clsx"

function Homepage() {
  // const [activeIndex, setActiveIndex] = useState(0)
  // const items = [
  //   {
  //     title:
  //       "Key metrics in rich visualization and real time to give your sales team a jumpstart",
  //   },
  //   {
  //     title:
  //       "Simple and intuitive user interface, ready to use for an enterprise",
  //   },
  //   {
  //     title: "Never lose sight of your customers using our sales automation ",
  //   },
  //   {
  //     title:
  //       "We enrich information about your customer for you to close opportunity",
  //   },
  //   {
  //     title: "Simple process to customize your sales process",
  //   },
  // ]

  // function next() {
  //   const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
  //   setActiveIndex(nextIndex)
  // }

  // function previous() {
  //   const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
  //   setActiveIndex(nextIndex)
  // }

  // function goToIndex(newIndex) {
  //   setActiveIndex(newIndex)
  // }
  // const slides = items.map((item) => (
  //   <CarouselItem>
  //     <div className="top-left22" style={{ top: "100px" }}>
  //       <h1>{item.title}</h1>
  //     </div>
  //   </CarouselItem>
  // ))
  return (
    <div>
      <div className="menu">
        <div className="megamenushow">
          <div
            className="mega-menu"
            style={{
              // maxHeight: "150px",
              marginLeft: "100px",
              marginRight: "100px",
            }}
          ></div>
        </div>
      </div>

      <div className="mobile_text">
        <div style={{ textAlign: "left" }}>
          Drive
          <span style={{ color: "rgb(0, 191, 255)" }}>
            {" "}
            DIGITAL TRANSFORMATION{" "}
          </span>
          <br />
          with Korero's solutions designed
          <br /> to meet your
          <span style={{ color: "rgb(0, 191, 255)" }}>
            {" "}
            industry-specific needs.
          </span>
          <span style={{ color: "rgb(0, 191, 255)" }}></span>
          <br />
          {/* <span style={{ color: "rgb(0, 191, 255)" }}>KEY METRICS</span> in rich
          visualization and{" "}
          <span style={{ color: "rgb(0, 191, 255)" }}>REAL TIME</span> for a
          jumpstart.
          <br />
          Simple and intuitive user interface,{" "}
          <span style={{ color: "rgb(0, 191, 255)" }}>
            READY TO USE OUT OF THE BOX
          </span>{" "}
          for an enterprise. */}
        </div>
      </div>
      <LandingScreen
        backgroundImage={`url(${contactImg})`}
        mobileBackgroundImage={`url(${contact_mobile})`}
        height="600px"
        // style={{
        //   backgroundImage: `url(${BGIMG})`,
        //   backgroundSize: "cover",
        // }}
      >
        {/* <div className="carosule">
          {" "}
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Carousel>
        </div> */}
        <div className="landing_image_main_box">
          <div className="landing_image_main_box_first_child">
            <div className="image_text_For_landing_page">
              Drive
              <span style={{ color: "white" }}> DIGITAL TRANSFORMATION </span>
              <br />
              with Korero's solutions designed
              <br /> to meet your
              <span style={{ color: "white" }}> industry-specific needs.</span>
              <span style={{ color: "white" }}></span>
            </div>
            <div className="button_for_landing_page_in_web">
              <a
                onClick={(e) => {
                  trackCustomEvent({
                    category: "start your free trial caraousel button ",

                    action: "Click",

                    label: "Start your free trial button in home page",

                    value: 43,
                  })
                }}
                className="banner_buttons"
                href={`${loginUrl}/register/Option`}
                target="_blank"
              >
                START YOUR 30 DAYS FREE TRIAL NOW!
              </a>
            </div>
          </div>
        </div>
      </LandingScreen>

      <div className="">
        <div className="landing-button_forMobile_feature">
          <a
            onClick={(e) => {
              trackCustomEvent({
                category: "start your free trial caraousel button ",

                action: "Click",

                label: "Start your free trial button in home page",

                value: 43,
              })
            }}
            className="banner_buttons"
            href={`${loginUrl}/register/Option`}
            target="_blank"
          >
            START YOUR 30 DAYS FREE TRIAL NOW!
          </a>
        </div>
        {/* First Box */}
        {/* <div style={{border:"1px solid red"}}</div> */}
        <div className="feature1" id="1">
          <h1>Staffing</h1>
        </div>
        <div className="spacer"></div>
        {/* <div className="wrapping"> */}
        <div className="feature-left-box-content1">
          {/* <p className="feature-left-box-content"> */}
          Korero is your GoTo platform for not only closing opportunities but
          also for tracking status of each profile positioned for the vacancy.
          <br />
          <br /> RecruitPro is an in-app add on module that users from Staffing
          industry need to enable to access the additional features,
          <br /> admin of the enterprise can easily enable this module in the
          Control Tower found in the Users section. <br />
          For more information kindly visit{" "}
          <a href="/knowledgehub">Knowledge Hub</a>. This module will be charged
          additionally.
        </div>
        <br />
        <br />
        {/* </div> */}
        <div className="main "></div>
        {/* <div className="feature-left-box ">
        <p className="feature-left-box-content">
        Korero is your go to platform for not only closing opportunities but also for 
        tracking status of each profile positioned for the vacancy.
            </p>
          </div> */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-right-box ">
            <div className="feature-image">
              <img src={staffing} alt="staffing" className="border-image" />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Filter profiles based on multiple parameters </h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img src={staffing} alt="dasboard" className="border-image" />
              </div>
            </div>

            <p className="feature-left-box-content">
              Korero helps organize your contact list and better manage
              candidate database so that staffing companies can leverage Korero
              for managing profiles as well as customer relationships.
              <br />
              <br />
              Users will be able to update each candidate’s profile with the key
              parameters like availability, billing rate and key words that
              helps narrow down the search for the potential profile.
            </p>
          </div>
        </div>
        {/* mobile view */}
        <div className="feature-main-box_mobile ">
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={staffing}
                alt="Configurable_fiscal_period_hires"
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              {/* Configurable Fiscal period */}
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img src={staffing} alt="dasboard" className="border-image" />
              </div>
            </div>

            <p className="feature-left-box-content">
              Korero helps organize your contact list and better manage
              candidate database so that staffing companies can leverage Korero
              for managing profiles as well as customer relationships.
              <br />
              Users will be able to update each candidate’s profile with the key
              parameters like availability, billing rate and key words that
              helps narrow down the search for the potential profile.
            </p>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <br />
        <br /> <br /> <br />
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-left-box ">
            {/* <div className="feature-left-box-heading">
              Real time Business performance indicators and analytics for CEOs and Sales leaders
            </div> */}

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={RecruitPro_Workflow_hires}
                  alt="RecruitPro_Workflow_hires"
                  className="border-image"
                />
              </div>
            </div>
            <div className="feature-left-box-heading">
              <h2>Define Workflows for your processes</h2>
            </div>
            <br />
            <p className="feature-left-box-content">
              Create Workflow for each type of requirement, define the number of
              stages as deemed appropriate. Easy to use and simplified User
              Interface ensures that Workflows can be configured in moments and
              your team can start using the workflows and tagging candidates at
              the earliest. This also ensures governance with easy reporting of
              metrics. <br />
              <br />
              RecruitPro also enables automation of communication to the
              candidate on the result through email, further the message can be
              customized using templates.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={RecruitPro_Workflow_hires}
                alt="RecruitPro_Workflow_hires"
                className="border-image"
              />
            </div>
          </div>
        </div>
        <div className="feature-main-box_mobile ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              Define Workflows for your processes
            </div>
            {/* <div className="feature-left-box-heading">
              Real time Business performance indicators and analytics for CEOs and Sales leaders
            </div> */}

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={RecruitPro_Workflow_hires}
                  alt="RecruitPro_Workflow_hires"
                  className="border-image"
                />
              </div>
            </div>
            <br />

            <p className="feature-left-box-content">
              Create Workflow for each type of requirement, define the number of
              stages as deemed appropriate. Easy to use and simplified User
              Interface ensures that Workflows can be configured in moments and
              your team can start using the workflows and tagging candidates at
              the earliest. This also ensures governance with easy reporting of
              metrics. <br />
              <br />
              RecruitPro also enables automation of communication to the
              candidate on the result through email, further the message can be
              customized using templates.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={RecruitPro_Workflow_hires}
                alt="RecruitPro_Workflow_hires"
                className="border-image"
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          <ScrollToTop />
        </div>
        <div className="feature-container-3">
          <Signup />
        </div>
        {/* <div className="feature-divider"></div> */}
        {/* IT Services */}
        {/* <div className="feature1" id="2">
        Information Technology 
        </div> */}
        {/* <div className="spacer"></div> */}
        {/* <div className="feature-left-box-content1">


        
Relationship management is the hallmark in the IT sector as the same sponsors are associated with a number 
of opportunities .<br/> Typical opportunities tend to be complex in terms of effort estimation, 
commercials and generally have a longer sales cycle.<br/> Incumbents typically have an advantage over the challengers,
 Korero’s actionable insights ensures that you are always<br/> focused on the winnable opportunities and never miss any
  follow-ups. Leverage Boost to engage with customer from a  content perspective. 
       
      </div> */}
        {/* <div className="spacer"></div> */}
        {/* </div> */}
        {/* <div className="main "> */}
        {/* </div> */}
        {/* <div className="feature-left-box ">
        <p className="feature-left-box-content">
        Korero is your go to platform for not only closing opportunities but also for 
        tracking status of each profile positioned for the vacancy.
            </p>
          </div> */}
        <br /> <br />
        {/* <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Currency_hires}
                alt="staffing"
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
             Live currency exchange for Proposals & Reports 
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
              <img
                src={Currency_hires}
                alt="staffing"
                className="border-image"
              />
              </div>
            </div>
            {/* <div className="feature-divider"></div> */}
        {/* <p className="feature-left-box-content">
            Korero supports Live currency conversion that helps sales executives get an insight into the proposal
             values for cross border transactions. For easy reporting and KPI calculation, data is published in
              local / reported currency so that both users and enterprises can compare performance and progress.

            </p>
          </div>
        </div> */}
        {/* mobile view */}
        {/* <div className="feature-main-box_mobile "> */}
        {/* <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={staffing}
                alt="Configurable_fiscal_period_hires"
                className="border-image"
              />
            </div>
          </div> */}
        {/* <div className="feature-left-box "> */}
        {/* <div className="feature-left-box-heading">
            Live currency exchange for Proposals & Reports 
            </div> */}
        {/* <div className="spacer"></div> */}
        {/* <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={staffing}
                  alt="dasboard"
                  className="border-image"
                />
              </div>
            </div> */}
        {/* <p className="feature-left-box-content">
            Relationship management is the hallmark in the IT sector as the same sponsors are associated with a number 
of opportunities . Typical opportunities tend to be complex in terms of effort estimation, 
commercials and generally have a longer sales cycle. Incumbents typically have an advantage over the challengers,
 Korero’s actionable insights ensures that you are always focused on the winnable opportunities and never miss any
  follow-ups. Leverage Boost to engage with customer from a  content perspective. 
            </p> */}
        {/* <img src={Custom_Function_hires} alt="bgimggf" className="border-image" /> */}
        {/* Opportunities need a lot of collaboration between various functions to provide the best suited proposal 
            to the customer including a possibility of on-boarding an Alliance partner. Korero allows you to include 
            such users as required so that tasks and approvals can be completed. Automation through self service ensures
             business users can easily configure without any support. Leads can be managed automatically with the
              non-responsive ones returning back to the pool managed by the admin for a possible future reconnect. 
              Quotations and invoices can be automatically mailed to the customer also as a pdf on generating the document.
               Escalations has been automated 
            so that relevant users will be notified and governance ensured leading to finally customer satisfaction. */}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="spacer"></div>
        <div className="spacer"></div>
        <br/>
        <br/> <br/> <br/> */}
        {/* <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        > */}
        {/* <div className="feature-left-box "> */}
        {/* <div className="feature-left-box-heading">
              Real time Business performance indicators and analytics for CEOs and Sales leaders
            </div> */}
        {/* <div className="spacer"></div> */}
        {/* <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={Custom_Function_hires}
                  alt="Custom_Function_hires"
                  className="border-image"
                />
              </div>
            </div> */}
        {/* <div className="feature-left-box-heading">
            Collaboration for Proposal Quality
            </div> */}
        {/* <br/> */}
        {/* <p className="feature-left-box-content">
            
            Opportunities need a lot of collaboration between various functions to provide the best suited proposal 
            to the customer including a possibility of on-boarding an Alliance partner. 
            Korero allows you to include such users as required so that tasks and approvals can be completed.
            </p> */}
        {/* </div> */}
        {/* <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Custom_Function_hires}
                alt="Custom_Function_hires"
                className="border-image"
              />
            </div>
          </div> */}
        {/* </div> */}
        {/* <div className="feature-main-box_mobile ">
          <div className="feature-left-box ">
          <div className="feature-left-box-heading">
         Collaboration for Proposal Quality
            </div>
            {/* <div className="feature-left-box-heading">
              Real time Business performance indicators and analytics for CEOs and Sales leaders
            </div> */}
        {/* <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={Custom_Function_hires}
                  alt="Custom_Function_hires"
                  className="border-image"
                />
              </div>
            </div>
            <br/> */}
        {/* <p className="feature-left-box-content"> */}
        {/* Opportunities need a lot of collaboration between various functions to provide the best suited proposal  */}
        {/* to the customer including a possibility of on-boarding an Alliance partner. */}
        {/* Korero allows you to include such users as required so that tasks and approvals can be completed. */}
        {/* </p> */}
        {/* </div> */}
        {/* <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Custom_Function_hires}
                alt="Custom_Function_hires"
                className="border-image"
              />
            </div>
          </div> */}
        {/* </div> */}
        {/* <br/><br/><br/> */}
        {/* <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        > */}
        {/* <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Automation_Leads_Ageing_Distribution_highres}
                alt="Automation_Leads_Ageing_Distribution_highres"
                className="border-image"
              />
            </div>
          </div> */}
        {/* <div className="feature-left-box "> */}
        {/* <div className="feature-left-box-heading">
            Automation improves Productivity 
            </div> */}
        {/* <div className="spacer"></div> */}
        {/* <div className="feature-image-hide-on-web">
              <div className="feature-image">
              <img
                src={Automation_Leads_Ageing_Distribution_highres}
                alt="Automation_Leads_Ageing_Distribution_highres"
                className="border-image"
              />
              </div>
            </div> */}
        {/* <div className="feature-divider"></div> */}
        {/* <p className="feature-left-box-content">
             Automation through self service ensures
             business users can easily configure without any support. Leads can be managed automatically with the
              non-responsive ones returning back to the pool managed by the admin for a possible future reconnect. 
              Quotations and invoices can be automatically mailed to the customer also as a pdf on generating the document.
               Escalations has been automated 
            so that relevant users will be notified and governance ensured leading to finally customer satisfaction.

            </p> */}
        {/* </div> */}
        {/* </div> */}
        {/* mobile view */}
        {/* <div className="feature-main-box_mobile "> */}
        {/* <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Automation_Leads_Ageing_Distribution_highres}
                alt="Automation_Leads_Ageing_Distribution_highres"
                className="border-image"
              />
            </div>
          </div> */}
        {/* <div className="feature-left-box "> */}
        {/* <div className="feature-left-box-heading">
            Automation improves Productivity 
            </div> */}
        {/* <div className="spacer"></div> */}
        {/* <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={Automation_Leads_Ageing_Distribution_highres}
                  alt="Automation_Leads_Ageing_Distribution_highres"
                  className="border-image"
                />
              </div>
            </div> */}
        {/* <p className="feature-left-box-content">
             Automation through self service ensures
             business users can easily configure without any support. Leads can be managed automatically with the
              non-responsive ones returning back to the pool managed by the admin for a possible future reconnect. 
              Quotations and invoices can be automatically mailed to the customer also as a pdf on generating the document.
               Escalations has been automated 
            so that relevant users will be notified and governance ensured leading to finally customer satisfaction.
            </p> */}
        {/* <img src={Custom_Function_hires} alt="bgimggf" className="border-image" /> */}
        {/* Opportunities need a lot of collaboration between various functions to provide the best suited proposal 
            to the customer including a possibility of on-boarding an Alliance partner. Korero allows you to include 
            such users as required so that tasks and approvals can be completed. Automation through self service ensures
             business users can easily configure without any support. Leads can be managed automatically with the
              non-responsive ones returning back to the pool managed by the admin for a possible future reconnect. 
              Quotations and invoices can be automatically mailed to the customer also as a pdf on generating the document.
               Escalations has been automated 
            so that relevant users will be notified and governance ensured leading to finally customer satisfaction. */}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="feature-divider"></div> */}
        {/* Second Box */}
        {/* /* right image box */}
        {/* <div className="feature-main-box_mobile "> */}
        {/* /* right image box */}
        {/* /* left image box */}
        {/* <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={img7}
                alt="Configurablefiscalyear"
                className="border-image"
              />
            </div> */}
        {/* </div> */}
        {/* <div className="feature-left-box "> */}
        {/* <div className="feature-left-box-heading"> */}
        {/* Workflows integrated with Sales processes */}
        {/* </div> */}
        {/* <div className="spacer"></div> */}
        {/* <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img src={img7} alt="img7" className="border-image" />
              </div>
            </div> */}
        {/* <p className="feature-left-box-content"> */}
        {/* Start creating and manage opportunities across their life cycle by
              using Korero’s out of the box template.
              <div className="spacer"></div>
              Build your own sales processes (available in Professional
              subscription mode) for an accelerated opportunity management. */}
        {/* </p> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  )
}

export default Homepage
