import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Solutions from "../modules/Solutions/Solutions"
// import { Helmet } from "react-helmet"

const SecondPage = () => (
  <Layout>
    <SEO title="Solutions" 
    description="Drive DIGITAL TRANSFORMATION
    with Korero's solutions designed
    to meet your industry-specific needs."/>
    <Solutions/>
    {/* <Helmet> */}
    {/* <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      {/* lang: en_US */}
    {/* </script>  */}
    {/* </Helmet> */}
  </Layout>
)

export default SecondPage
