import React, { Component } from "react"

export default class ScrollToTop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_visible: false,
    }
  }

  componentDidMount() {
    var scrollComponent = this
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility()
    })
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      })
    } else {
      this.setState({
        is_visible: false,
      })
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  render() {
    const { is_visible } = this.state
    return (
      <div className="scroll-to-top">
        {is_visible && (
          <div onClick={() => this.scrollToTop()} style={{ cursor: "pointer" }}>
            <svg width="23" height="22" xmlns="http://www.w3.org/2000/svg">
              <g>
                <title>background</title>
                <rect
                  fill="#101111"
                  id="canvas_background"
                  height="24"
                  width="25"
                  y="-1"
                  x="-1"
                />
                <g
                  display="none"
                  overflow="visible"
                  y="0"
                  x="0"
                  height="100%"
                  width="100%"
                  id="canvasGrid"
                >
                  <rect
                    fill="url(#gridpattern)"
                    stroke-width="0"
                    y="0"
                    x="0"
                    height="100%"
                    width="100%"
                  />
                </g>
              </g>
              <g>
                <title>Back to Top</title>
                <path
                  stroke="#000"
                  id="svg_1"
                  d="m0.75,10.726l10.5,-9.976l10.5,9.976l-5.25,0l0,10.024l-10.5,0l0,-10.024l-5.25,0z"
                  stroke-width="1.5"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
        )}
      </div>
    )
  }
}
